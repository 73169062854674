import { NavbarBrand, Navbar, Container } from 'reactstrap';

const SignUpNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <div className="nav-link-icon nav-link">
            <NavbarBrand>
              <img
                alt="logo"
                src={require('../../assets/img/brand/flowShare-logo-white.png')}
              />
            </NavbarBrand>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default SignUpNavbar;
