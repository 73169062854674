import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Routes from './routes';

const useAuth = () => {
  const token = localStorage.getItem('POR');
  const roleName = useSelector(
    (state) => state.organizationMembership.roleName
  );

  return { isAuthenticated: !!token, roleName };
};

const ProtectedRoutes = (props) => {
  const { Component, requiredRole } = props;
  const { isAuthenticated, roleName } = useAuth();
  console.log(requiredRole);

  if (!isAuthenticated) {
    return <Navigate to={Routes.auth.login} />;
  }

  if (requiredRole && !requiredRole.includes(roleName)) {
    return <Navigate to={Routes.app.dashboard} />;
  }

  return <Component />;
};
export default ProtectedRoutes;
