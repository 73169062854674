import { Buffer } from 'buffer';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Container } from 'reactstrap';

import logo from '../assets/img/brand/flowShare-logo.png';
import NotificationType from '../constants/notificationType';
import { setJustLoggedIn } from '../redux/actions/misc.actions';
import { setOrganization } from '../redux/actions/organization.actions';
import { setOrganizationMembership } from '../redux/actions/organizationMembership.actions';
import { setUser } from '../redux/actions/users.actions';
import Routes from '../routes/routes';
import { memberService } from '../services/member.service';
import { organizationService } from '../services/organization.service';
import { userService } from '../services/user.service';
import { openNotification } from '../utlis/notification';

const Authorization = () => {
  const dispatch = useDispatch();
  const { t, ready } = useTranslation(['global', 'notifications', 'auth']);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [client, setClient] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [responseType, setResponseType] = useState('');
  const [scope, setScope] = useState('');
  const [state, setState] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setClient(searchParams.get('client'));
    setRedirectUri(searchParams.get('redirect_uri'));
    setResponseType(searchParams.get('response_type'));
    setScope(searchParams.get('scope'));
    setState(searchParams.get('state'));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('POR');
    if (token) {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (password !== '' && email !== '') {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email, password]);

  if (!ready) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await userService.authorization(
        email,
        password,
        client,
        redirectUri,
        responseType,
        scope,
        state
      );

      localStorage.setItem(
        'POR',
        Buffer.from(response.accessToken).toString('base64')
      );

      localStorage.setItem(
        'PORREF',
        Buffer.from(response.refreshToken).toString('base64')
      );

      const user = await userService.getUserProfile();

      dispatch(setUser(user));

      const organization = await organizationService.getCurrentOrganization();

      dispatch(setOrganization(organization));

      const membership = await memberService.getCurrentUserMembership();
      dispatch(setOrganizationMembership(membership));

      dispatch(setJustLoggedIn(true));

      window.location.replace(response.url);
    } catch (e) {
      setLoading(false);
      openNotification(
        t('notifications:emailOrPasswordWrong'),
        NotificationType.ERROR
      );
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Form
        className="p-5 bg-white rounded shadow d-flex flex-column align-items-center"
        onSubmit={handleSubmit}
      >
        <div className="mb-4 mt--4" style={{ height: '60px', width: '202px' }}>
          <img src={logo} alt="Logo" className="img-fluid" />
        </div>
        <FormGroup className="mb-3 w-100">
          <Label for="email" className="mb-2">
            {t('global:email')}
          </Label>
          <Input
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            defaultValue={email}
          />
        </FormGroup>
        <FormGroup className="mb-3 w-100">
          <Label for="password" className="mb-2">
            {t('global:password')}
          </Label>
          <Input
            type="password"
            name="password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            defaultValue={password}
          />
        </FormGroup>
        <div className="mb-3 w-100 text-underline">
          <a
            href={`${Routes.auth.passwordForgot}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#005883' }}
          >
            {t('auth:signInForgotPasswordText')}
          </a>
        </div>

        <Button
          color="primary"
          type="submit"
          disabled={!readyToSend}
          className="w-100"
          style={{ marginRight: '-6px' }}
        >
          {t('global:login')}
          {loading && <span className="spinner-border spinner-border-sm" />}
        </Button>
        <div className="text-center mt-5 mb--4 w-100 text-underline">
          <a
            href={`${Routes.app.dashboard}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#005883' }}
          >
            {t('global:backToPortal')}
          </a>
        </div>
      </Form>
    </Container>
  );
};

export default Authorization;
