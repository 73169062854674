import ContentLoader from 'react-content-loader';

const FlowUsageLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        width={50}
        height={23}
        viewBox="0 0 50 23"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
      </ContentLoader>
    </>
  );
};

export default FlowUsageLoader;
