import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Container, Navbar, Row } from 'reactstrap';

const Header = (props) => {
  const { t, ready } = useTranslation('global');

  const organization = useSelector((state) => state.organization);
  if (!ready) return null;

  return (
    <>
      <div className="header bg-gradient-info opacity-8 pb-2 pt-5 pt-md-8">
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Row style={{ width: '100%' }}>
              <Col className="col-12 d-flex  justify-content-between">
                <div className="h4 text-white text-uppercase pl-lg-0 pl-md-0 pl-sm-3 pl-3">
                  {t(`global:${props.activeLocation}`)}
                </div>

                <div className="h4 text-white">
                  {organization.name.charAt(0).toUpperCase() +
                    organization.name.slice(1)}
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

Header.prototype = {
  activeLocation: PropTypes.string.isRequired,
};

export default Header;
