import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import PasswordRequirements from './PasswordRequirements';
import NOTIFICATION_TYPE from '../constants/notificationType';
import { PASSWORD_CHANGE_REGEX } from '../constants/passwordChangeRegex';
import { setJustLoggedIn } from '../redux/actions/misc.actions';
import { setUser } from '../redux/actions/users.actions';
import Routes from '../routes/routes';
import { userService } from '../services/user.service';
import { openNotification } from '../utlis/notification';

const FirstLogin = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, ready } = useTranslation('billing');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);

  useEffect(() => {
    if (
      firstName !== '' &&
      lastName !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      new RegExp(`${PASSWORD_CHANGE_REGEX}`).test(password) &&
      password === confirmPassword
    ) {
      setReadyToSend(false);
    } else {
      setReadyToSend(true);
    }
  }, [password, confirmPassword, firstName, lastName]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await userService.firstLoginAccountSetup(
        password,
        firstName,
        lastName
      );

      openNotification(
        t('notifications:accountSetupSuccess'),
        NOTIFICATION_TYPE.SUCCESS
      );

      dispatch(setUser(response));

      dispatch(setJustLoggedIn(true));
      navigate(Routes.app.dashboard);
    } catch (e) {
      setLoading(false);
      setReadyToSend(false);
      document.getElementById('firstLoginPasswordReset').reset();
      openNotification(e.error, NOTIFICATION_TYPE.ERROR);
    }
  };

  return (
    <Card lg={'5'} md={'7'}>
      {/*<CardHeader>*/}
      {/*  <h4>{t('auth:firstLoginHeading')}</h4>*/}
      {/*</CardHeader>*/}
      <CardBody>
        <Row>
          <Col className="order-xl-1" xl="6">
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <label className="form-control-label">
                    {t('global:firstNameLabel')}
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={t('global:firstNamePlaceHolderText')}
                      type="text"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      defaultValue={firstName}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <label className="form-control-label">
                    {t('global:lastNameLabel')}
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={t('global:lastNamePlaceholderText')}
                      type="text"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      defaultValue={lastName}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Form id={'firstLoginPasswordReset'} onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('auth:firstLoginNewPasswordLabel')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('auth:firstLoginNewPasswordPlaceHolder')}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    defaultValue={password}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('auth:firstLoginConfirmNewPasswordLabel')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t(
                      'auth:firstLoginConfirmNewPasswordPlaceHolder'
                    )}
                    type="password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    defaultValue={confirmPassword}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <div className="text-center ">
                  <Button
                    disabled={readyToSend}
                    className="my-4 float-left"
                    color="primary"
                    type="submit"
                  >
                    {t('global:submit')}
                    {loading && (
                      <span className="spinner-border spinner-border-sm" />
                    )}
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </Col>
          <Col className="order-xl-1" xl="6">
            <label className="form-control-label">
              {t('auth:firstLoginPasswordRequirementsLabel')}
            </label>
            <p>{t('auth:firstLoginPasswordRequirementsText')}</p>
            <PasswordRequirements
              liClass={'mb-2'}
              ulClass={'small text-muted'}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default FirstLogin;
