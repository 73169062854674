import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import friendlyIcon from '../../assets/img/icons/info.svg';
const InputComponent = ({
  question,
  setQuestion,
  isBotTyping,
  handleSubmit,
  chatHistory,
  clearChat,
}) => {
  const { t, ready } = useTranslation('chatbot');
  const [openConversationPopUp, setOpenConversationPopUp] = useState(false);
  const [doNotShow, setDoNotShow] = useState(false);

  useEffect(() => {
    const dontShow = localStorage.getItem('doNotShowClearChatWarning');
    setDoNotShow(dontShow === 'true');
  }, []);

  if (!ready) return null;

  const sendRequest = () => {
    if (chatHistory.length >= 3) {
      setOpenConversationPopUp(!openConversationPopUp);
    } else {
      handleSubmit();
    }
  };

  const togglePopUp = () => {
    if (doNotShow) {
      clearChat();
    } else {
      setOpenConversationPopUp(!openConversationPopUp);
    }
  };
  return (
    <div
      className="col-xs-12 col-md-5 mb-3"
      style={{ paddingRight: '0px', paddingLeft: '0px' }}
    >
      <Input
        type={'textarea'}
        value={question}
        placeholder={t('questionPlaceHolderText')}
        onChange={(e) => setQuestion(e.target.value)}
        className="form-control"
        style={{
          fontWeight: '20px',
          height: '125px',
          maxHeight: '125px',
        }}
        rows="7"
        required
      ></Input>
      <div className="d-flex mt-3 button-container">
        {chatHistory.length > 0 && (
          <Button
            onClick={() => {
              togglePopUp();
            }}
            color="transparent"
            className={` d-block btn-lg `}
            title="Clicking this will erase the chat history."
            style={{
              border: '1px solid black',
              borderRadius: '30px',
              width: `196px`,
            }}
            disabled={chatHistory.length <= 0}
          >
            {t('startNewConversationButtonText')}
          </Button>
        )}

        {chatHistory.length <= 0 && (
          <Button
            onClick={() => {
              togglePopUp();
            }}
            color="transparent"
            className={` d-block btn-lg `}
            title="Clicking this will erase the chat history."
            style={{
              border: '1px solid black',
              borderRadius: '30px',
              width: `110px`,
            }}
            disabled={chatHistory.length <= 0}
          >
            {t('clearButtonText')}
          </Button>
        )}

        {chatHistory.length <= 0 && (
          <Button
            disabled={isBotTyping || question === ''}
            color="primary"
            type={'submit'}
            className={` d-block btn-lg `}
            onClick={sendRequest}
            style={{
              width: `110px`,
            }}
          >
            {t('submitButtonText')}
          </Button>
        )}

        {chatHistory.length > 0 && (
          <Button
            disabled={isBotTyping || question === '' || chatHistory.length >= 3}
            color="primary"
            type={'submit'}
            className={` d-block btn-lg `}
            onClick={sendRequest}
            style={{
              width: `196px`,
            }}
          >
            {t('askFollowUpQuestionButtonText')}
          </Button>
        )}
      </div>
      <div
        className="mt-4"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img
          src={friendlyIcon}
          alt="Emoji"
          style={{ marginRight: '10px', height: '50px', width: '78px' }}
        />
        <span>
          {t('assistConsecutiveQuestionNoticeTextFirstHalf')} <br />{' '}
          {t('assistConsecutiveQuestionNoticeTextSecondHalf')}{' '}
        </span>
      </div>
      <Modal
        isOpen={openConversationPopUp}
        toggle={() => {
          setOpenConversationPopUp(!openConversationPopUp);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setOpenConversationPopUp(!openConversationPopUp);
          }}
          style={{ paddingRight: '10px', paddingTop: '10px' }}
        ></ModalHeader>
        <ModalBody
          className={
            'd-flex flex-column align-items-center justify-content-center '
          }
        >
          <div>
            <img
              src={friendlyIcon}
              className={'mt--5 mb-2'}
              style={{ height: '65px', width: '65px' }}
              alt={'friendly-emoji'}
            />
          </div>
          <div style={{ marginBottom: '5px' }}>
            {chatHistory.length >= 3 && (
              <p>{t('followQuestionModalWarningHeading')}</p>
            )}
            {chatHistory.length < 3 && (
              <p>
                {t('followQuestionModalWarningMessageFirstHalf')} <br />
                &nbsp;&nbsp;{t('followQuestionModalWarningMessageSecondHalf')}
              </p>
            )}
          </div>
          <Button
            color="primary"
            onClick={() => {
              setOpenConversationPopUp(!openConversationPopUp);
              clearChat();
            }}
          >
            {t('startNewConversationButtonText')}
          </Button>
          <Label check style={{ marginTop: '15px' }}>
            <Input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  localStorage.setItem('doNotShowClearChatWarning', 'true');
                  setDoNotShow(true);
                }
              }}
            />
            {t('followQuestionModalWarningChoice')}
          </Label>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InputComponent;
