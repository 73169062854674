import NOTIFICATION_TYPES from 'constants/notificationType';
import { toast } from 'react-toastify';

export function openNotification(message, type, autoCloseNotification = true) {
  switch (type) {
    case NOTIFICATION_TYPES.ERROR:
      toast.error(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        autoCloseNotification: autoCloseNotification,
      });

      break;
    case NOTIFICATION_TYPES.SUCCESS:
      toast.success(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        autoCloseNotification: autoCloseNotification,
      });
      break;

    case NOTIFICATION_TYPES.WARNING:
      toast.warn(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        autoCloseNotification: autoCloseNotification,
      });
      break;

    default:
      toast.info(message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        autoCloseNotification: autoCloseNotification,
      });
  }
}
