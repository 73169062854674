import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';

import { handleError } from '../utlis/errorHandler';
import { getAuthHeader } from '../utlis/getAuthHeader';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];

export const flowService = {
  deleteFlow,
  getFlow,
  getAllFlows,
  updateFlowMetaInformation,
  getFlowVersions,
  getFlowsCount,
};

function getFlowsCount() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(`${BACKEND_URL}/api/flow/flow-count`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
function getFlowVersions(id) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };
  return axios
    .post(`${BACKEND_URL}/api/flow/flow-versions/${id}`, {}, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
function getAllFlows(page, limit, searchText, sort) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  let url = `${BACKEND_URL}/api/flow?`;

  if (page) url += `page=${page}&`;
  if (limit) url += `limit=${limit}&`;
  if (searchText) url += `search_text=${searchText}&`;
  if (sort) url += `sort=${sort}`;

  return axios
    .get(url, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function getFlow(id, identifier, version = null) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };
  let params = `${id}/${identifier}`;
  if (version) {
    params = `${params}?version=${version}`;
  }
  return axios
    .get(`${BACKEND_URL}/api/flow/${params}`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function deleteFlow(ids) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  ids.forEach((id) => {
    return axios
      .delete(`${BACKEND_URL}/api/flow/${id}`, requestConfig)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return handleError(error);
      });
  });
}

async function updateFlowMetaInformation(status, id) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    status: status,
  };

  return axios
    .put(`${BACKEND_URL}/api/flow/meta-information/${id}`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
