import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import ShareFlowModal from './ShareFlowModal';
import shareBlack from '../assets/img/icons/share-black.svg';
import routes from '../routes/routes';

const FlowViewerNavbar = (props) => {
  const organization = useSelector((state) => state.organization);

  const { t, ready } = useTranslation('global');

  const token = localStorage.getItem('POR');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const originalTitle = document.title;
    document.title = 'FlowShare Library';

    return () => {
      document.title = originalTitle;
    };
  }, []);

  if (!ready) return null;

  return (
    <div className={'flow-viewer-navbar'}>
      <Navbar
        className="navbar-horizontal fixed-left navbar-light bg-white pt-0"
        expand="md"
      >
        <Container className="px--9 custom-container">
          <button className="navbar-toggler pt-4 " id="navbar-collapse-main">
            <span className="navbar-toggler-icon " />
          </button>
          <div className="nav-link-icon nav-link pt-4">
            <NavbarBrand href="https://getflowshare.com/">
              <img
                alt="..."
                src={require('../assets/img/brand/flowShare-logo.png')}
              />
            </NavbarBrand>
          </div>
          {token && organization.id === props.organizationId && (
            <a
              style={{ color: '#212529' }}
              href={routes.app.manageFlows}
              className="d-none d-md-block"
            >
              <div
                className="nav-link-icon pt-4"
                style={{
                  color: '#212529',
                  font: '14px "Open Sans", sans-serif',
                  fontWeight: '600',
                  paddingBottom: '4px',
                }}
              >
                {token
                  ? `${t('global:flowViewerBackToLibrary')}`
                  : `${t('global:login')}`}
              </div>
            </a>
          )}

          <UncontrolledCollapse
            navbar
            toggler="#navbar-collapse-main"
            style={{ marginLeft: '20px' }}
          >
            <div className="navbar-collapse-header d-md-none">
              <Row style={{ paddingLeft: '16px' }}>
                {token && organization.id === props.organizationId && (
                  <a
                    style={{ color: '#212529' }}
                    href={routes.app.manageFlows}
                    className="d-md-block"
                  >
                    <div
                      id="down-mobile"
                      style={{
                        color: '#212529',
                        font: '14px "Open Sans", sans-serif',
                        fontWeight: '600',
                        paddingTop: '10px',
                      }}
                    >
                      {t('global:flowViewerBackToLibrary')}
                    </div>
                  </a>
                )}
                <Col className="collapse-close ">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>

            <Nav className="ml-auto" style={{ gap: '15px' }} navbar>
              {!props.flowNotFound &&
                token &&
                organization.id === props.organizationId && (
                  <>
                    <NavItem>
                      <NavLink className="nav-link-icon p-0 d-md-none">
                        <span onClick={toggleModal}>
                          <img
                            src={shareBlack}
                            alt="icon"
                            style={{ height: '19px', width: '22px' }}
                          />
                          {t('global:flowViewerShareWithALinkButtonText')}
                        </span>
                      </NavLink>
                      <NavLink className="nav-link-icon p-0 d-none d-md-block">
                        <Button
                          style={{
                            backgroundColor: 'transparent',
                            borderRadius: '30px 30px 30px 30px',
                          }}
                          onClick={toggleModal}
                          className="w-100"
                        >
                          <img
                            src={shareBlack}
                            alt="icon"
                            style={{ height: '19px', width: '22px' }}
                          />
                          {t('global:flowViewerShareWithALinkButtonText')}
                        </Button>
                      </NavLink>
                    </NavItem>

                    <hr className="d-md-none my-2" style={{ width: '100%' }} />
                  </>
                )}
              <NavItem>
                <NavLink
                  className="nav-link-icon p-0 d-md-none"
                  as="a"
                  href={t('global:trialWebsite')}
                >
                  {t('global:flowViewerButtonText')}
                </NavLink>
                <NavLink
                  className="nav-link-icon p-0 d-none d-md-block"
                  as="a"
                  href={t('global:trialWebsite')}
                >
                  <Button color="primary" className="w-100">
                    {t('global:flowViewerButtonText')}
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>

      <ShareFlowModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        url={window.location.href}
        status={props.status}
      />
    </div>
  );
};

export default FlowViewerNavbar;
