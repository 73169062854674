import Header from 'components/Header';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';

import { organizationService } from '../services/organization.service';
import { handleError } from '../utlis/errorHandler';

const Billing = () => {
  const organization = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);
  const { t, ready } = useTranslation('billing');

  if (!ready) return null;

  const generateSession = async (e) => {
    e.preventDefault();

    setLoading(true);
    async function fetchData() {
      return await organizationService.getStripeSession(organization.id);
    }

    fetchData()
      .then((res) => {
        window.location.replace(res);
      })
      .catch((error) => {
        return handleError(error);
      });
  };

  return (
    <>
      <Header activeLocation={'billing'} />
      <Container className={'mt-lg--5 mt-md--5 mt-sm--3 mt--3'} fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('billing:heading')}</h3>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="8">
                    <Button
                      color="primary"
                      className="float-left"
                      onClick={generateSession}
                      disabled={loading}
                    >
                      {t('billing:redirectButtonText')}
                      {loading && (
                        <span className="spinner-border spinner-border-sm" />
                      )}
                    </Button>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs="8">{t('billing:cardBodyText')} </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Billing;
