import { Outlet } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

const FlowShareLayOut = () => {
  // const mainContent = React.useRef(null);
  // const location = useLocation();
  // const { t, ready } = useTranslation(['auth']);

  // React.useEffect(() => {
  //   document.body.classList.add('bg-default');
  //   return () => {
  //     document.body.classList.remove('bg-default');
  //   };
  // }, []);

  // React.useEffect(() => {
  //   document.body.style.backgroundColor = '#f1f1f1';
  //   // This effect only runs after `ready` is true and the component has mounted
  //   if (ready && mainContent.current) {
  //     document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //     mainContent.current.scrollTop = 0;
  //   }
  // }, [ready, location]);

  // if (!ready) return null;

  return (
    <Container
      fluid
      className={'p-0 m-0'}
      style={{ backgroundColor: '#f7fafc' }}
    >
      {/* <FlowShareNavbar /> */}
      {/* <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">{t('auth:authTitle')}</h1>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div> */}
      {/* <Container className="mt--8">
          <Row className="justify-content-center"> */}
      <Row
        className="justify-content-left"
        style={{ width: '100%', backgroundColor: 'yellow' }}
      >
        <Outlet />
      </Row>
      {/* </Row>
        </Container> */}
    </Container>
  );
};

export default FlowShareLayOut;
