import Sidebar from 'components/Sidebar';
import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';

const App = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <Sidebar
        {...props}
        logo={{
          innerLink: '/app/',
          imgSrc: require('../assets/img/brand/flowShare-logo.png'),
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <Outlet />
      </div>
    </>
  );
};

export default App;
