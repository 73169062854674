import { useEffect } from 'react';

const useGoogleTagManager = (gtmId) => {
  useEffect(() => {
    if (!gtmId) return;

    // 1. Inject GTM script in the <head>
    const headScript = document.createElement('script');
    headScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(headScript);

    // 2. Inject GTM noscript tag right after the opening <body>
    const bodyNoscript = document.createElement('noscript');
    bodyNoscript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.insertBefore(bodyNoscript, document.body.firstChild);

    // Clean up the scripts when component is unmounted
    return () => {
      if (document.head.contains(headScript)) {
        document.head.removeChild(headScript);
      }
      if (document.body.contains(bodyNoscript)) {
        document.body.removeChild(bodyNoscript);
      }
    };
  }, [gtmId]);
};

export default useGoogleTagManager;
