import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import friendlyIcon from '../../assets/img/icons/info.svg';

const NoFlowModal = ({ triggerOpen, onClose }) => {
  const { t, ready } = useTranslation(['chatbot']);

  if (!ready) return;
  return (
    <Modal isOpen={triggerOpen} toggle={onClose} centered>
      <ModalHeader
        toggle={onClose}
        style={{ paddingRight: '10px', paddingTop: '10px' }}
      ></ModalHeader>
      <ModalBody
        className={
          'd-flex flex-column align-items-center justify-content-center '
        }
      >
        <div>
          <img
            src={friendlyIcon}
            className={'mt--5 mb-2'}
            style={{ height: '65px', width: '65px' }}
            alt={'friendly-emoji'}
          />
        </div>
        <div style={{ marginBottom: '5px', textAlign: 'center' }}>
          <p>
            {t('chatbot:noFlowModalHeading')} <br />
            {t('chatbot:noFlowModalSubHeading')}
          </p>
        </div>
        <Button
          color="primary"
          onClick={() =>
            (window.location.href = 'https://support.getflowshare.com')
          }
        >
          {t('chatbot:noFlowButton')}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default NoFlowModal;
