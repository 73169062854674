import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';

import { handleError } from '../utlis/errorHandler';
import { getAuthHeader } from '../utlis/getAuthHeader';

const FLOW_ASSIST_URL = process.env.REACT_APP_FLOW_ASSIST_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];

export const flowAssistService = { chat, feedback, fetchImages };
function chat(msg, history) {
  const requestConfig = {
    headers: getAuthHeader(),
  };

  const data = {
    msg: msg,
    history: history.length === 3 ? [] : history,
    lang: browserLanguage,
  };

  return axios
    .post(`${FLOW_ASSIST_URL}/get`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function fetchImages(source, stepNumber) {
  const requestConfig = {
    headers: getAuthHeader(),
  };

  const data = {
    source: source,
    step_number: stepNumber,
  };

  return axios
    .post(`${FLOW_ASSIST_URL}/fetch_images`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function feedback(data) {
  const requestConfig = {
    headers: getAuthHeader(),
  };

  return axios
    .post(`${FLOW_ASSIST_URL}/feedback`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log('unable to send feedback', error);
    });
}
