import { useTranslation } from 'react-i18next';
import { Col, Card, CardBody, CardHeader, Button, List } from 'reactstrap';
import PlanMainText from './PlanMainText';
import PlanPrice from './PlanPrice';

const FreePlan = () => {
  const { t, ready } = useTranslation(['plans']);
  if (!ready) return null;

  const planData = {
    planName: t('plans:freePlanData.planName'),
    features: [
      {
        text: t('plans:freePlanData.features.captureAndShare'),
      },
      {
        text: t('plans:freePlanData.features.worksWithApps'),
      },
      { text: t('plans:freePlanData.features.branding') },
    ],
  };

  return (
    <Col xs="12" sm="8" md="7" lg="4">
      <Card className="border-0 border-bottom border-primary shadow-sm">
        <CardHeader
          className="plan-card-header"
          style={{ backgroundColor: '#B2C9D3' }}
        >
          <h2 style={{ color: 'white' }}>{planData.planName}</h2>
        </CardHeader>
        <CardBody className="p-4 p-xxl-5 plan-body-height">
          <PlanMainText text={t('plans:freePlanData.mainHeading')} />

          <hr className="my-3" />
          <div className={`ml-3 card-nested-body`}>
            <PlanPrice price={'$0*'} />

            <Button
              color="primary"
              className="rounded-pill plan-button "
              disabled={true}
            >
              {t('plans:plansChoosePlanButton')}
            </Button>
          </div>

          <hr className="my-3 mt--3" />
          <h4>{t('plans:freePlanData.featuresHeading')}</h4>
          <List type="unstyled">
            {planData.features.map((feature, index) => (
              <li key={index} className="mt-2 d-flex align-items-start">
                <div className="icon-container">
                  {feature.available ? (
                    <i className="fas fa-times"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </div>
                <span className="text-container">{feature.text}</span>
              </li>
            ))}
          </List>
        </CardBody>
      </Card>
    </Col>
  );
};

export default FreePlan;
