import Header from 'components/Header';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import PricingPage from './pricePlans/PricingPage';
import TranslationTable from './pricePlans/TranslationTable';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganization } from 'redux/actions/organization.actions';
import { organizationService } from 'services/organization.service';
import { useNavigate } from 'react-router';
import Routes from 'routes/routes';
import { useTranslation } from 'react-i18next';

const Plans = () => {
  const { t, ready } = useTranslation(['plans']);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState(false);
  const [hasStatusParam, setHasStatusParam] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const status = params.get('status');

    setHasStatusParam(!!status);
    setIsCheckoutSuccess(status === 'success');

    const updateOrganization = async () => {
      try {
        const organization = await organizationService.getCurrentOrganization();
        dispatch(setOrganization(organization));
      } catch (error) {
        navigate(Routes.app.managePlans);
      }
    };

    const removeStatusParam = () => {
      params.delete('status');
      window.history.replaceState({}, document.title, url.pathname);
    };

    if (status === 'success') {
      setInterval(async function () {
        await updateOrganization();
        removeStatusParam();
      }, 3000);
    } else if (status === 'cancel') {
      removeStatusParam();
    }
  }, []);

  useEffect(() => {
    if (feedbackText !== '') {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [feedbackText]);
  if (!ready) return null;

  const sendFeedback = () => {
    organizationService.sendFeedbackEmail(user.email, feedbackText);
    setHasStatusParam(!setHasStatusParam);
  };
  return (
    <>
      <Header activeLocation={'plans'} />
      <Container className={'mt-lg--5 mt-md--5 mt-sm--3 mt--3 '} fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow pb-3">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('plans:plansMainHeading')}</h3>
                  </Col>
                </Row>
              </CardHeader>

              <PricingPage />
            </Card>
          </Col>
        </Row>
        <Row className="ml-2 mt-1">
          <span> {t('plans:plansFooter')}</span>
        </Row>
        <Row className="mt-5 mb-2">
          <Col lg="6" md="10" sm="12">
            <TranslationTable />
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        isOpen={hasStatusParam}
        toggle={() => {
          setHasStatusParam(!hasStatusParam);
        }}
      >
        <ModalHeader style={{ paddingLeft: '12px', paddingRight: '12px' }}>
          {isCheckoutSuccess ? (
            <h3>{t('plans:planUpgradeSuccessHeader')}</h3>
          ) : (
            <h3>{t('plans:planUpgradeFailedHeader')}</h3>
          )}
        </ModalHeader>
        <ModalBody style={{ marginTop: '-35px', paddingLeft: '12px' }}>
          {isCheckoutSuccess ? (
            <div>{t('plans:planUpgradeSuccessMessage')}</div>
          ) : (
            <div>
              <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                {t('plans:planUpgradeFailedTextTopText')}
              </p>
              <p>{t('plans:planUpgradeFailedTextMainMessage')}</p>
              <Input
                placeholder={t('plans:planUpgradeFailedInputPlaceHolder')}
                onChange={(e) => {
                  setFeedbackText(e.target.value);
                }}
                defaultValue={feedbackText}
                type="textarea"
              />
              <Button
                className="float-right"
                style={{ marginTop: '1rem' }}
                color="primary"
                onClick={() => {
                  sendFeedback();
                }}
                disabled={!readyToSend}
              >
                {t('plans:planUpgradeFailedButton')}
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Plans;
