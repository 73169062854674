export const SET_JUST_LOGGED_IN = 'SET_JUST_LOGGED_IN';

export const setJustLoggedIn = (justLoggedIn) => ({
  type: SET_JUST_LOGGED_IN,
  justLoggedIn: justLoggedIn,
});

export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX';

export const setActiveIndex = (key, activeIndex) => ({
  type: SET_ACTIVE_INDEX,
  setActiveIndex: { key, activeIndex },
});

export const CLEAR_ACTIVE_INDEXES = 'CLEAR_ACTIVE_INDEXES';

export const clearActiveIndexes = () => ({
  type: CLEAR_ACTIVE_INDEXES,
});
