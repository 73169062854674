import ContentLoader from 'react-content-loader';

const VerticalIconLoader = ({ width }) => {
  return (
    <td style={{ width: `${width}%` }}>
      <ContentLoader width="24px" height="24px" viewBox="0 0 24 24">
        <circle cx="12" cy="5" r="2"></circle>
        <circle cx="12" cy="12" r="2"></circle>
        <circle cx="12" cy="19" r="2"></circle>
      </ContentLoader>
    </td>
  );
};

export default VerticalIconLoader;
