import ContentLoader from 'react-content-loader';

import TableCellSkeleton from './TableCellSkeleton';
import VerticalIconLoader from './VerticalIconLoader';

const MemberLoader = () => {
  return (
    <tbody>
      <tr>
        <td>
          <ContentLoader width="250" height="48" viewBox="0 0 250 48" speed={2}>
            <circle cx="24" cy="24" r="24" />
            <rect x="58" y="14" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
        </td>
        <TableCellSkeleton width={100} />
        <TableCellSkeleton width={100} />
        <TableCellSkeleton width={100} />
        <VerticalIconLoader width={4} />
      </tr>
    </tbody>
  );
};

export default MemberLoader;
