import { useState } from 'react';
import SignUpBasic from './SignUpBasic';
import SignUpMain from './SignUpMain';
import SignUpNavbar from './SignUpNavbar';
import useGoogleTagManager from 'components/hook/GoogleTagManager';

const SignUpWrapper = () => {
  const [showSignupMain, setShowSignUpMain] = useState(false);
  const [email, setEmail] = useState('');
  const [showPostSignUpMessage, setShowPostSignUpMesasge] = useState(false);

  useGoogleTagManager('GTM-WN6ZC4L');

  const changeShowSignUp = (email) => {
    setEmail(email);
    setShowSignUpMain(true);
  };

  const changePostSignUpMessage = () => {
    setShowPostSignUpMesasge(!showPostSignUpMessage);
  };

  return (
    <>
      <SignUpNavbar />

      {showSignupMain && !showPostSignUpMessage && (
        <SignUpMain
          email={email}
          changePostSignUpMessage={changePostSignUpMessage}
        />
      )}

      {!showSignupMain && !showPostSignUpMessage && (
        <SignUpBasic changeShowSignUp={changeShowSignUp} />
      )}
    </>
  );
};

export default SignUpWrapper;
