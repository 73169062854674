import ContentLoader from 'react-content-loader';
import { CardBody, CardTitle, Col, Row } from 'reactstrap';

const LicenseLoader = () => {
  return (
    <CardBody>
      <CardTitle>
        <span>
          <ContentLoader height={20} width={280}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
          </ContentLoader>
        </span>
      </CardTitle>
      <Row>
        <Col>
          <ContentLoader height={20} width={200}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
          </ContentLoader>
        </Col>
      </Row>
      <Row>
        <Col>
          <ContentLoader height={20} width={200}>
            <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
          </ContentLoader>
        </Col>
      </Row>
    </CardBody>
  );
};

export default LicenseLoader;
