import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Row,
  Col,
  Container,
} from 'reactstrap';

import blackLessThan from '../../assets/img/icons/black-less-than.svg';

const ChatBotNavBar = () => {
  const { t, ready } = useTranslation('chatbot');

  if (!ready) return null;
  return (
    <div className={'chat-navbar'}>
      <Navbar
        className="navbar-horizontal fixed-left navbar-light  pt-0"
        expand="md"
      >
        <Container fluid>
          <div className=" nav-link">
            <NavbarBrand href="https://getflowshare.com/">
              <img
                alt="..."
                src={require('../../assets/img/brand/flowShare-logo.png')}
              />
            </NavbarBrand>
          </div>
          <button className="navbar-toggler " id="navbar-collapse-main">
            <span className="navbar-toggler-icon " />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/app">
                    <img
                      alt="..."
                      src={require('../../assets/img/brand/flowShare-logo.png')}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/app" style={{ color: 'black' }}>
                  <span className="nav-link-inner--text">
                    <img
                      src={blackLessThan}
                      alt="icon"
                      className={'less-icon'}
                    />
                    {t('backToFlowShareButtonText')}
                  </span>
                </Link>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default ChatBotNavBar;
