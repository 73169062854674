import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PasswordRequirements = (props) => {
  const { t, ready } = useTranslation(['global']);
  if (!ready) return null;

  return (
    <ul className={`${props.ulClass} ml--4`}>
      <li className={props.liClass}>
        {t('global:passwordRequirementsMinCharactersText')}
      </li>
      <li className={props.liClass}>
        {t('global:passwordRequirementsAtLesteOneLetterText')}
      </li>
      <li className={props.liClass}>
        {t('global:passwordRequirementsOneNumberText')}
      </li>
      <li className={props.liClass}>
        {t('global:atLeasOneSpecialCharacterText')}
      </li>
    </ul>
  );
};

PasswordRequirements.propTypes = {
  liClass: PropTypes.string,
  ulClass: PropTypes.string,
};
export default PasswordRequirements;
