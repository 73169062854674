import { Buffer } from 'buffer';
import { userService } from 'services/user.service';

export function handleError(error) {
  if (error.response?.status === 401) {
    // get refresh token
    const refreshToken = localStorage.getItem('PORREF');
    if (refreshToken) {
      const buff = Buffer.from(refreshToken, 'base64');
      const str = buff.toString('ascii');

      return userService
        .refreshToken(str)
        .then((response) => {
          localStorage.clear();
          localStorage.setItem(
            'POR',
            Buffer.from(response.accessToken).toString('base64')
          );
          localStorage.setItem(
            'PORREF',
            Buffer.from(response.refreshToken).toString('base64')
          );
          window.location.reload();
        })
        .catch((refreshError) => {
          userService.logout(true);
          return Promise.reject(refreshError);
        });
    } else {
      // Refresh token not available, perform logout
      userService.logout();
    }
  }

  return Promise.reject(error.response.data);
}
