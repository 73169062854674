import NOTIFICATION_TYPES from 'constants/notificationType';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Label,
} from 'reactstrap';
import Routes from 'routes/routes';
import { userService } from 'services/user.service';
import { openNotification } from 'utlis/notification';

const LoginFlowShare = () => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (password !== '' && email !== '') {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email, password]);

  function loadScript(src, onLoad) {
    const script = document.createElement('script');
    script.src = src;
    script.onload = onLoad;
    script.onerror = () => console.error(`Failed to load script ${src}`);
    document.head.appendChild(script);
  }

  const getSource = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('source');
  };

  const sendResponseToFlowShare = (response, command) => {
    loadScript('/qwebchannel.js', () => {
      if (window.QWebChannel && window.qt && window.qt.webChannelTransport) {
        new window.QWebChannel(window.qt.webChannelTransport, function (
          channel
        ) {
          const bridge = channel.objects.bridge;

          window.sendDataToQt = function (message) {
            bridge.receiveMessage(JSON.stringify(message));
          };

          window.sendDataToQt({ response: response, command: command });
        });
      } else {
        alert('QWebChannel or qt.webChannelTransport is not available');
      }
    });
  };

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await userService.qtLogin(email, password);

      sendResponseToFlowShare(response, 'closeDialogue');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setReadyToSend(false);

      openNotification(
        t(`notifications:${error.error}`),
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  return (
    <>
      <Col className="col-12 p-0" style={{ backgroundColor: '#f1f1f1' }}>
        <Card
          className="bg-secondary border-0"
          style={{ backgroundColor: '#f1f1f1' }}
        >
          <CardBody style={{ paddingLeft: '65px', paddingRight: '27px' }}>
            <h2 className="text-center mb">Sign in</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label> {t('auth:signUpBasicEmailLabel')}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t('auth:signUpBasicEmailPlaceHolder')}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label> {t('global:password')}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    placeholder={t('global:password')}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    defaultValue={password}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={!readyToSend}
                  className="mt-0 mb-2"
                  type="submit"
                  color={'primary'}
                >
                  {t('global:signIn')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
            <div className="d-flex justify-content-between mt-2">
              <div
                onClick={() => {
                  sendResponseToFlowShare(
                    {
                      url: `${process.env.REACT_APP_FRONTEND_URL}${Routes.auth.signUp}?source=flowshare`,
                    },
                    'openLink'
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                {`${t('global:noAccount')} `}
                <span className="text-underline">
                  {`${t('global:signUp')}!`}
                </span>
              </div>
              <div
                onClick={() => {
                  sendResponseToFlowShare(
                    {
                      url: `${process.env.REACT_APP_FRONTEND_URL}${Routes.auth.passwordForgot}`,
                    },
                    'openLink'
                  );
                }}
                style={{ cursor: 'pointer' }}
                rel="noopener noreferrer"
                className="text-underline"
              >
                {t('auth:signInForgotPasswordText')}
              </div>
            </div>
            {getSource() !== 'authorization' && (
              <>
                <hr className="my-3" />
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h3>{t('auth:alreadyACustomer')}</h3>

                  <Button
                    type="submit"
                    color={'primary'}
                    onClick={() => {
                      sendResponseToFlowShare(
                        {
                          message: `Enter key dialogue`,
                        },
                        'enterKey'
                      );
                    }}
                  >
                    {t('auth:enterKeyText')}
                  </Button>
                </div>

                <hr className="my-3" />

                <div className="d-flex flex-column justify-content-center align-items-center mb--1">
                  <div
                    type="submit"
                    color={'primary'}
                    onClick={() => {
                      sendResponseToFlowShare(
                        {
                          message: `Close FlowShare`,
                        },
                        'closeFlowShare'
                      );
                    }}
                    className="text-underline"
                  >
                    {t('auth:closeAppText')}
                  </div>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default LoginFlowShare;
