import TableCellSkeleton from './TableCellSkeleton';
import VerticalIconLoader from './VerticalIconLoader';

const FlowLoader = () => {
  return (
    <>
      <TableCellSkeleton width={100} />
      <TableCellSkeleton width={60} />
      <TableCellSkeleton width={60} />
      <TableCellSkeleton width={60} />
      <TableCellSkeleton width={60} />
      <TableCellSkeleton width={60} />
      <VerticalIconLoader width={4} />
    </>
  );
};
export default FlowLoader;
