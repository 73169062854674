import { Navigate } from 'react-router-dom';

import Routes from './routes';

const useAuth = () => {
  const token = localStorage.getItem('POR');
  return !!token;
};

const PublicRoutes = (props) => {
  const { Component } = props;
  const auth = useAuth();

  return auth ? <Navigate to={Routes.app.dashboard} /> : <Component />;
};

export default PublicRoutes;
