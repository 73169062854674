import axios from 'axios';
import 'lightbox2';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iframe from 'react-iframe';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DOMParser } from 'xmldom';

import FlowViewerLoader from './contentLoaders/FlowViewerLoader';
import FlowNotFound from './FlowNotFound';
import logo from '../assets/img/brand/flowShare-logo.svg';
import { flowService } from '../services/flow.service';

const FlowViewer = () => {
  const { t, ready } = useTranslation('global');
  const { id, identifier } = useParams();
  const [flow, setFlow] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [flowNotFound, setFlowNotFound] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const parser = new DOMParser();
  const divRef = useRef(null);

  useEffect(() => {
    const getFlow = async () => {
      try {
        const response = await flowService.getFlow(id, identifier);
        setFlow(response);
        if (response.url && response.type === 'static') {
          const urlObject = new URL(response.url);
          axios
            .get(urlObject.toString())
            .then((response) => {
              const parsedHtml = parser.parseFromString(
                response.data,
                'text/html'
              );

              const elementWithTocId = parsedHtml.getElementById('toc');

              const container = document.createElement('div');
              container.innerHTML = response.data;
              if (!elementWithTocId) {
                container.querySelector('.main-content .col-01').style.width =
                  '0%';
                container.querySelector('.main-content .col-02').style.width =
                  '0%';
                container.querySelector('.main-content .col-03').style.width =
                  '100%';
              }

              const styleElement = container.querySelector('#style');
              if (styleElement) {
                styleElement.href = '/flowViewer/static_style_embed.css';
              }
              const updatedHtmlString = container.innerHTML;
              setHtmlContent(updatedHtmlString);

              setLoading(false);
            })
            .catch((error) => console.error(error));
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        setFlowNotFound(true);
      }
    };

    getFlow();
  }, []);

  if (!ready) return null;

  return (
    <>
      {flow?.type === 'static' && !flowNotFound}
      {!flowNotFound && flow?.type === 'static' && (
        <>
          <div
            ref={divRef}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          ></div>
          <Button
            className="floating-button"
            onClick={() => window.open('https://getflowshare.com/', '_blank')}
          >
            {t('global:madeWithButtonText')}
            <img alt="..." width={'100px'} height={'31px'} src={logo} />
          </Button>
        </>
      )}

      {!loading && flowNotFound && (
        <>
          <FlowNotFound />
        </>
      )}

      {!flowNotFound && flow?.type === 'interactive' && (
        <div className={`full-screen-container`}>
          <Iframe
            url={flow?.url}
            width="100%"
            height="100%"
            overflow={'hidden'}
            display="block"
            position="relative"
          />
        </div>
      )}

      {loading && <FlowViewerLoader />}
    </>
  );
};

export default FlowViewer;
