import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  Tooltip,
} from 'reactstrap';

import LicenseLoader from './contentLoaders/LicenseLoader';
import Header from './Header';
import NotificationType from '../constants/notificationType';
import routes from '../routes/routes';
import { licenseService } from '../services/license.service';
import { openNotification } from '../utlis/notification';
import { useSelector } from 'react-redux';

const ManageLicenses = () => {
  const organizationMembership = useSelector(
    (state) => state.organizationMembership
  );
  const { t, ready } = useTranslation(['global', 'licenseKey']);
  const [licenseKeys, setLicenseKeys] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toolTipMessage, setToolTipMessage] = useState(t('global:clickToCopy'));
  const [toolTipState, setToolTipState] = useState({});

  useEffect(() => {
    fetchLicenseKeys();
  }, []);

  const fetchLicenseKeys = async () => {
    try {
      const licenseKeys = await licenseService.getLicenses();
      setLicenseKeys(licenseKeys);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  if (!ready) return null;

  const deActivate = async (e, keyId, id, versionId) => {
    e.preventDefault();

    try {
      await licenseService.deActivate(id, keyId, versionId);
      const licenseKeysUpdated = licenseKeys.map((licenseKey) => {
        if (licenseKey.id === id) {
          const filteredAct = licenseKey.activeIpAddress.act.filter(
            (activation) => activation.id !== keyId
          );
          return {
            ...licenseKey,
            usedActivation: licenseKey.usedActivation - 1,
            activeIpAddress: {
              ...licenseKey.activeIpAddress,
              act: filteredAct,
            },
          };
        }
        return licenseKey;
      });

      setLicenseKeys(licenseKeysUpdated);
      openNotification(
        t('notifications:licenseKeyDeactivateSuccess'),
        NotificationType.SUCCESS
      );
    } catch (error) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  return (
    <>
      <Header activeLocation={'manageLicenseKey'} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col className={'col-12'}>
            <Card>
              <ListGroup flush>
                <ListGroupItem>
                  <CardHeader className="d-flex justify-content-between h3 bg-white border-0">
                    <div>{t('licenseKey:activations')}</div>
                    {organizationMembership.roleName === 'admin' && (
                      <Link to={routes.app.billing}>
                        {t('licenseKey:manageSubscription')}
                      </Link>
                    )}
                  </CardHeader>
                </ListGroupItem>
                {!loading && !licenseKeys && (
                  <CardBody>
                    {t('licenseKey:noLicenseKeyFoundMessage')}{' '}
                  </CardBody>
                )}

                {licenseKeys &&
                  !loading &&
                  licenseKeys.map((licenseKey, index) => {
                    if (licenseKey.isFloating === 'false') {
                      return (
                        <ListGroupItem key={index}>
                          <CardBody key={index}>
                            <CardTitle>
                              <Tooltip
                                placement="top"
                                isOpen={toolTipState[index]}
                                target={`license-key-${index}`}
                              >
                                {toolTipMessage}
                              </Tooltip>
                              <span
                                className={'h3'}
                                id={`license-key-${index}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    licenseKey.licenseKey
                                  );
                                  setToolTipMessage(t('global:copied'));
                                }}
                                onMouseEnter={() => {
                                  setToolTipMessage(t('global:clickToCopy'));
                                  setToolTipState({
                                    ...toolTipState,
                                    [index]: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  setToolTipMessage(t('global:clickToCopy'));
                                  setToolTipState({
                                    ...toolTipState,
                                    [index]: false,
                                  });
                                }}
                              >
                                {licenseKey.licenseKey}
                                <i className="ni ni-ungroup ml-2" />
                              </span>
                            </CardTitle>
                            <Row>
                              <Col className={'col-12'}>
                                {`${t('licenseKey:usedActivations')} ${
                                  licenseKey.usedActivation
                                }`}
                              </Col>
                              <Col>
                                {`${t('licenseKey:totalActivations')} ${
                                  licenseKey.totalActivation
                                }`}
                              </Col>
                            </Row>
                            <Row>
                              <Col className={'col-12'}>
                                <div
                                  style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                  }}
                                >
                                  {licenseKey.activeIpAddress && (
                                    <Table striped borderless>
                                      <thead>
                                        <tr>
                                          <th
                                            className={'pl-0'}
                                            style={{ fontSize: '.85rem' }}
                                          >
                                            {t('licenseKey:ipAddress')}
                                          </th>
                                          <th style={{ fontSize: '.85rem' }}>
                                            {t('licenseKey:dateActivated')}
                                          </th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {licenseKey.activeIpAddress?.act.map(
                                          (ipAddress, index) => (
                                            <tr key={index}>
                                              <td>{ipAddress.ip}</td>
                                              <td>
                                                {new Date(
                                                  ipAddress.date
                                                ).toLocaleString('en-us', {
                                                  month: 'long',
                                                  day: 'numeric',
                                                  year: 'numeric',
                                                })}
                                              </td>
                                              <td
                                                onClick={(e) => {
                                                  deActivate(
                                                    e,
                                                    ipAddress.id,
                                                    licenseKey.id,
                                                    licenseKey.versionId
                                                  );
                                                }}
                                              >
                                                <a href="/#">
                                                  <u>
                                                    {t('global:deActivate')}
                                                  </u>
                                                </a>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </ListGroupItem>
                      );
                    } else {
                      return (
                        <ListGroupItem key={index}>
                          <CardBody key={index}>
                            <CardTitle>
                              <Tooltip
                                placement="top"
                                isOpen={toolTipState[index]}
                                target={`license-key-${index}`}
                              >
                                {toolTipMessage}
                              </Tooltip>
                              <span
                                className={'h3'}
                                id={`license-key-${index}`}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    licenseKey.licenseKey
                                  );
                                  setToolTipMessage(t('global:copied'));
                                }}
                                onMouseEnter={() => {
                                  setToolTipMessage(t('global:clickToCopy'));
                                  setToolTipState({
                                    ...toolTipState,
                                    [index]: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  setToolTipMessage(t('global:clickToCopy'));
                                  setToolTipState({
                                    ...toolTipState,
                                    [index]: false,
                                  });
                                }}
                              >
                                {licenseKey.licenseKey}
                                <i className="ni ni-ungroup ml-2" />
                              </span>
                            </CardTitle>
                            <Row>
                              <Col className={'col-12 h4'}>
                                {`${t('licenseKey:licensePool')}: ${
                                  licenseKey.totalActivation
                                }`}
                              </Col>
                            </Row>
                          </CardBody>
                        </ListGroupItem>
                      );
                    }
                  })}

                {loading && <LicenseLoader />}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ManageLicenses;
