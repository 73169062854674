import Header from 'components/Header';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
} from 'reactstrap';

import PasswordUpdate from './PasswordUpdate';
import NotificationType from '../constants/notificationType';
import { setUser } from '../redux/actions/users.actions';
import { userService } from '../services/user.service';
import { openNotification } from '../utlis/notification';

const UserProfile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t, ready } = useTranslation(['profile', 'global', 'notifications']);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [position, setPosition] = useState(user.position);
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      lastName !== '' &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [lastName, email, user.lastName, user.email]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const response = await userService.updateAccount(
        firstName,
        lastName,
        email,
        position,
        user.id
      );
      setLoading(false);

      dispatch(setUser(response));

      openNotification(
        t('notifications:accountUpdatedSuccess'),
        NotificationType.SUCCESS
      );
    } catch (e) {
      setLoading(false);
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  return (
    <>
      <Header activeLocation={'profile'} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0 pt-3">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('profile:profileHeading')}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-secondary shadow">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {t('global:firstNameLabel')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        defaultValue={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                        id="input-first-name"
                        placeholder={t('global:firstNamePlaceHolderText')}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-last-name"
                      >
                        {t('global:lastNameLabel')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        defaultValue={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        id="input-last-name"
                        placeholder={t('global:lastNamePlaceholderText')}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-email"
                      >
                        {t('global:email')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        defaultValue={user.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder={t('global:email')}
                        type="email"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-position"
                      >
                        {t('profile:positionLabel')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        defaultValue={user.position}
                        onChange={(e) => {
                          setPosition(e.target.value);
                        }}
                        id="input-position"
                        placeholder={t('profile:positionPlaceHolderText')}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  disabled={!readyToSend}
                  className="float-left"
                  onClick={handleSubmit}
                >
                  {t('global:save')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <PasswordUpdate />
      </Container>
    </>
  );
};

export default UserProfile;
