import Header from 'components/Header.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import dashboardVideo from '../assets/videos/customer-portal-welcome-video-1.mp4';
import { setJustLoggedIn } from '../redux/actions/misc.actions';
import routes from '../routes/routes';
import dashboardDownloadIcon from '../assets/img/icons/dashboard_download.svg';
import dashboarInviteIcon from '../assets/img/icons/dashboard_invite.svg';
import dashboardSupportIcon from '../assets/img/icons/dashboard_support.svg';

const Index = () => {
  const { t, ready } = useTranslation(['index', 'global']);
  const dispatch = useDispatch();
  const misc = useSelector((state) => state.misc);
  const user = useSelector((state) => state.user);
  const organizationMembership = useSelector(
    (state) => state.organizationMembership
  );
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#eef8ff';
  }, []);
  useEffect(() => {
    if (misc.justLoggedIn && user.firstName === '' && user.lastName === '') {
      setOpenPopUp(true);
    } else if (misc.justLoggedIn) {
      dispatch(setJustLoggedIn(false));
    }
  });

  if (!ready) return null;

  const toggle = () => {
    dispatch(setJustLoggedIn(false));
    setOpenPopUp(false);
  };

  const handleClick = () => {
    setJustLoggedIn(false);
    navigate(routes.app.userProfile);
  };

  return (
    <>
      <Header activeLocation={t('index:dashboard')} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row className="d-flex flex-column flex-lg-row custom-row">
          <Col lg="8" md="12" className="mb-4 mb-lg-0 custom-video-col">
            <Card className="bg-secondary shadow h-100">
              <CardHeader className="bg-white border-0">
                {t('index:cardHeaderText')}
              </CardHeader>
              <CardBody className="video-container">
                <video
                  className="responsive-video"
                  title="dashboardVideo"
                  controls
                >
                  <source src={dashboardVideo} type="video/mp4" />
                  Your browser does not support the video.
                </video>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="12" className="d-flex flex-column custom-cards-col">
            <Card className="bg-secondary shadow flex-grow-1 mb-4">
              <CardBody>
                <div className="row widget-direction">
                  <div
                    id="text"
                    className="col-lg-8 col-md-8 col-12 order-2 order-lg-1"
                  >
                    <h3>{t('index:downloadFlowShareWidgetHeader')}</h3>
                    <div>{t('index:downloadFlowShareText')}</div>
                    <Button
                      className="btn-primary-dashboard mt-3"
                      href={t('index:downloadLink')}
                    >
                      {t('index:downloadFlowShareButtonText')}
                    </Button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 order-1 order-lg-2 text-center mb-3 mb-lg-0">
                    <img
                      src={dashboardDownloadIcon}
                      style={{ maxWidth: '100%', height: 'auto' }}
                      alt="Download icon"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="bg-secondary shadow flex-grow-1 mb-4">
              <CardBody>
                <div className="row widget-direction">
                  <div
                    id="text"
                    className="col-lg-8 col-md-8 col-12 order-2 order-lg-1"
                  >
                    <h3>{t('index:inviteUserWidgetHeader')}</h3>
                    <div>{t('index:inviteUserWidgetText')}</div>
                    <Button
                      disabled={organizationMembership.roleName !== 'admin'}
                      className="btn-primary-dashboard mt-3"
                    >
                      <Link
                        style={{ color: '#2db677' }}
                        to={routes.app.manageMembers}
                      >
                        {t('global:manageMembers')}
                      </Link>
                    </Button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 order-1 order-lg-2 text-center mb-3 mb-lg-0">
                    <img
                      src={dashboarInviteIcon}
                      style={{ maxWidth: '100%', height: 'auto' }}
                      alt="Invite icon"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="bg-secondary shadow flex-grow-1 mb-4">
              <CardBody>
                <div className="row widget-direction">
                  <div
                    id="text"
                    className="col-lg-8 col-md-8 col-12 order-2 order-lg-1"
                  >
                    <h3> {t('index:getSupportWidgetHeader')}</h3>
                    <div>{t('index:getSupportText')}</div>
                    <Button className="btn-primary-dashboard mt-3">
                      <a
                        href={t('global:suportLink')}
                        style={{ color: '#2db677' }}
                        target="_blnk"
                      >
                        {t('index:getSupportButtonText')}
                      </a>
                    </Button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12 order-1 order-lg-2 text-center mb-3 mb-lg-0">
                    <img
                      src={dashboardSupportIcon}
                      style={{ maxWidth: '100%', height: 'auto' }}
                      alt="Support icon"
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={openPopUp} centered size="m" toggle={toggle}>
          <ModalHeader>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {t('index:popUpHeaderText')}
            </span>
          </ModalHeader>
          <ModalBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>{t('index:popUpMainText')}</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 'auto',
                gap: '19px',
              }}
            >
              <a href={'#'} style={{ marginTop: '10px' }} onClick={toggle}>
                {t('index:popUpCancelButton')}
              </a>
              <Button
                color="primary"
                className="btn-primary"
                onClick={handleClick}
              >
                {t('index:popUpOkButton')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default Index;
