import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';

import ChatBotNavBar from './ChatBotNavBar';
import DropdownItemChatBot from './DropdownItemChatBot';
import FeedbackModal from './FeedbackModal';
import InputComponent from './InputComponent';
import AssistAnswerLoader from './loader/AssistAnswerLoader';
import AssistImagesLoader from './loader/AssistImagesLoader';
import NoFlowModal from './NoFlowModal';
import thumbDown from '../../assets/img/icons/thumb_down.svg';
import thumbUp from '../../assets/img/icons/thumb_up.svg';
import { clearActiveIndexes } from '../../redux/actions/misc.actions';
import { flowService } from '../../services/flow.service';
import { flowAssistService } from '../../services/flowShareAssist.service';
import { isValidNumber, isValidUrl } from '../../utlis/utils';

const ChatBot = () => {
  const { t, ready } = useTranslation('chatbot');
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const slidesToShow = 1;
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState({
    imagesURLs: [],
  });
  const [openItems, setOpenItems] = useState({});
  const [triggerFeedbackModal, setTriggerFeedbackModal] = useState(false);
  const [feedbackType, setFeedbackType] = useState(null);
  const [triggerNoFlowModal, setTriggerNoFlowModal] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);

  useEffect(() => {
    const fetchFlows = async () => {
      try {
        const response = await flowService.getAllFlows();
        if (response.total <= 0) {
          setTriggerNoFlowModal(true);
        }
      } catch (e) {
        console.log('error in fetching flows', e);
      }
    };
    fetchFlows();
  }, []);

  if (!ready) return null;

  const toggleDropdown = (actualIndex) => {
    setOpenItems({
      ...openItems,
      [actualIndex]: !openItems[actualIndex],
    });
  };

  const handleSubmit = async () => {
    if (isBotTyping) return;

    setIsBotTyping(true);
    let response;

    try {
      response = await flowAssistService.chat(question, chatHistory);

      const pattern = /^i don'?t know\.?$/i;

      // Handle I dont know answer
      if (pattern.test(response.bot?.trim()) && response.bot.length <= 15) {
        //Do not show images
        response.answer = t('iDontKnowResponseBot');
        setCurrentQuestionAnswer(response);

        updateChatHistory(response);
      } else if (
        isValidUrl(response.source) &&
        isValidNumber(response.step_number)
      ) {
        //show the response and then load for images
        setCurrentQuestionAnswer(response);
        setIsBotTyping(false);
        setLoadingImages(true);
        try {
          const imagesResponse = await flowAssistService.fetchImages(
            response.source,
            response.step_number
          );
          response.showImages = imagesResponse.showImages;
          response.source = imagesResponse.source;
          response.step_image = imagesResponse.step_image;
          response.imagesURLs = imagesResponse.imagesURLs;
          response.base_url = imagesResponse.base_url;

          setActiveIndex(imagesResponse.index);
          setLoadingImages(false);
        } catch (e) {
          setLoadingImages(false);
        }

        updateChatHistory(response);
      } else {
        setCurrentQuestionAnswer(response);
        updateChatHistory(response);
      }
    } catch (e) {
      //TODO for some reason backend occurred into the error OR something happened in the if condition, handle that case
      console.log('backend occurred into error', e);
    } finally {
      setIsBotTyping(false);
      setQuestion('');
    }
  };

  const updateChatHistory = (latestMessage) => {
    setChatHistory((prevChatHistory) => [...prevChatHistory, latestMessage]);
  };

  const updateURL = (activeIndex = null) => {
    setCurrentQuestionAnswer((prevState) => ({
      ...prevState,
      source: `${prevState.base_url}?step=${currentQuestionAnswer.imagesURLs[activeIndex].name}`,
    }));
  };

  const next = () => {
    const nextIndex =
      activeIndex >= currentQuestionAnswer.imagesURLs.length - 1
        ? 0
        : activeIndex + 1;
    setActiveIndex(nextIndex);

    updateURL(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex <= 0
        ? currentQuestionAnswer.imagesURLs.length - 1
        : activeIndex - 1;
    setActiveIndex(nextIndex);

    updateURL(nextIndex);
  };

  const clearChatHistory = () => {
    setLoadingImages(false);
    setQuestion('');
    setChatHistory([]);
    setCurrentQuestionAnswer({ imagesURLs: [] });
    dispatch(clearActiveIndexes());
  };

  return (
    <>
      <ChatBotNavBar />
      <div className="code_llama pt-6 pl-8">
        <div className={'chatbot-title mb-4 '}>{t('assistHeading')}</div>
        <div className="row " style={{ gap: '1rem', alignItems: 'flex-start' }}>
          <InputComponent
            question={question}
            setQuestion={setQuestion}
            isBotTyping={isBotTyping}
            handleSubmit={handleSubmit}
            chatHistory={chatHistory}
            clearChat={clearChatHistory}
          />
          <div className="col-xs-12 col-md-5 pl-0 pr-0 response-box">
            <div
              style={{
                fontWeight: 'bold',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            >
              {chatHistory.length <= 0 && !isBotTyping && (
                <div className={'pl-3 lighter-text'}>
                  {t('outputPlaceHolder')}
                </div>
              )}
              {!isBotTyping && chatHistory.length > 0 && (
                <div className={'pl-3'}>{currentQuestionAnswer.user}</div>
              )}
            </div>

            {isBotTyping && <AssistAnswerLoader />}

            <pre className={'pl-3 pr-3 pre-wrap'}>
              {currentQuestionAnswer.answer}
            </pre>
            {isValidUrl(currentQuestionAnswer.source) && (
              <div className={'mt-3 pl-3'}>
                {' '}
                <span>
                  {t('assistSuggestionFirstHalf')}
                  <a
                    href={currentQuestionAnswer.source}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`"${currentQuestionAnswer.title}"`}
                  </a>{' '}
                  {t('assistSuggestionSecondHalf')}{' '}
                  {currentQuestionAnswer.author}.
                </span>
              </div>
            )}

            {loadingImages && <AssistImagesLoader />}

            {currentQuestionAnswer.showImages && !loadingImages && (
              <Carousel
                keyboard={false}
                className="mt-4 carousel-outer"
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                ride="carousel"
                interval={false}
              >
                {currentQuestionAnswer.imagesURLs.map((src, idx) => (
                  <CarouselItem key={idx}>
                    <a
                      href={currentQuestionAnswer.source}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={src.src} alt={`image-${idx}`} />
                    </a>
                  </CarouselItem>
                ))}

                {activeIndex > 0 && (
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                )}
                {activeIndex <
                  currentQuestionAnswer.imagesURLs.length - slidesToShow && (
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                )}
              </Carousel>
            )}
            {currentQuestionAnswer.bot && (
              <div
                className={'pl-3'}
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
              >
                {t('suggestionModalQuestion')}
                <span
                  onClick={() => {
                    setFeedbackType('positive');
                    setTriggerFeedbackModal((prev) => !prev);
                  }}
                  style={{
                    marginLeft: '0.5rem',
                    textDecoration: 'underline',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}
                >
                  {t('yes')}
                  <img
                    src={thumbUp}
                    alt="Yes Icon"
                    style={{
                      cursor: 'pointer',
                      height: '35px',
                      width: '35px',
                      marginBottom: '7px',
                    }}
                  />
                </span>
                <span
                  onClick={() => {
                    setFeedbackType('negative');
                    setTriggerFeedbackModal((prev) => !prev);
                  }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {t('No')}
                  <img
                    src={thumbDown}
                    alt="No Icon"
                    style={{
                      cursor: 'pointer',
                      height: '35px',
                      width: '35px',
                    }}
                  />
                </span>
              </div>
            )}

            {chatHistory
              .slice(0, -1)
              .reverse()
              .map((item, index) => {
                // The original index before reversing the array
                const actualIndex = chatHistory.length - 2 - index; // "-2" because latest answer is removed and one more minus cause of 0 indexed
                return (
                  <DropdownItemChatBot
                    key={actualIndex}
                    itemKey={actualIndex}
                    item={item}
                    index={index}
                    isOpen={openItems[actualIndex]}
                    onToggle={() => toggleDropdown(actualIndex)}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <FeedbackModal
        triggerOpen={triggerFeedbackModal}
        onClose={() => {
          setFeedbackType(null);
          setTriggerFeedbackModal(false);
        }}
        chatHistory={chatHistory}
        feedbackType={feedbackType}
      />
      <NoFlowModal
        triggerOpen={triggerNoFlowModal}
        onClose={() => {
          setTriggerNoFlowModal(false);
        }}
      />
    </>
  );
};

export default ChatBot;
