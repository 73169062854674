import {
  Card,
  CardText,
  CardBody,
  Button,
  List,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';

import translationIcon from '../../assets/img/icons/translator.svg';
import { useTranslation } from 'react-i18next';

const TranslationTable = () => {
  const { t, ready } = useTranslation(['plans']);

  if (!ready) return null;

  return (
    <>
      <Card>
        <CardHeader style={{ backgroundColor: '#0B77A4' }}>
          <h2 style={{ color: 'white' }}>
            {t('plans:translationTableMainHeading')}
          </h2>
        </CardHeader>
        <CardBody>
          <Row noGutters>
            <Col>
              <CardText>{t('plans:translationTableText')}</CardText>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg="7" md="8" sm="8">
              <List type="unstyled">
                <li className="mt-2 d-flex align-items-start">
                  <div className="icon-container">
                    <i className="fas fa-check"></i>
                  </div>
                  <span className="text-container">
                    {t('plans:translateTableFeatures.timeSaving')}
                  </span>
                </li>
                <li className="mt-2 d-flex align-items-start">
                  <div className="icon-container">
                    <i className="fas fa-check"></i>
                  </div>
                  <span className="text-container">
                    {t('plans:translateTableFeatures.autoTranslate')}
                  </span>
                </li>
                <li className="mt-2 d-flex align-items-start">
                  <div className="icon-container">
                    <i className="fas fa-check"></i>
                  </div>
                  <span className="text-container">
                    {t('plans:translateTableFeatures.autoUpdate')}
                  </span>
                </li>
                <li className="mt-2 d-flex align-items-start">
                  <div className="icon-container">
                    <i className="fas fa-check"></i>
                  </div>
                  <span className="text-container">
                    {t('plans:translateTableFeatures.highQuality')}
                  </span>
                </li>
              </List>
            </Col>
            <Col lg="5" md="4" sm="4" className="d-flex align-items-center">
              <img
                src={translationIcon}
                alt="translation-icon"
                height="120px"
                width="120px"
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button
                color="primary"
                className="rounded-pill"
                href={t('global:letsTalkUrl')}
              >
                {t('plans:translationContactUsButton')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default TranslationTable;
