import PLAN from 'constants/planType';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Card, CardBody, Button, List, CardHeader } from 'reactstrap';
import PlanMainText from './PlanMainText';
import PlanPrice from './PlanPrice';

const EnterprisePlan = () => {
  const organization = useSelector((state) => state.organization);
  const { t, ready } = useTranslation(['plans', 'global']);
  if (!ready) return null;
  const planData = {
    planName: t('plans:enterprisePlanData.planName'),
    features: [
      {
        text: t('plans:enterprisePlanData.features.floatingLicense'),
      },
      {
        text: t('plans:enterprisePlanData.features.rollOut'),
      },
      { text: t('plans:enterprisePlanData.features.customIntegration') },
      { text: t('plans:enterprisePlanData.features.successManager') },
    ],
  };

  return (
    <Col xs="12" sm="8" md="7" lg="4">
      <Card className="border-0 border-bottom border-primary shadow-sm">
        <CardHeader
          className="plan-card-header"
          style={{ backgroundColor: '#005883' }}
        >
          <h2 style={{ color: 'white' }}>{planData.planName}</h2>
        </CardHeader>
        <CardBody className="p-4 p-xxl-5 plan-body-height">
          <PlanMainText text={t('plans:enterprisePlanData.mainHeading')} />
          <hr className="my-3" />
          <div className={`ml-3 card-nested-body`}>
            <PlanPrice price={t('plans:enterprisePlanData.letsTalkText')} />
            <Button
              disabled={organization.plan === PLAN.ENTERPRISE}
              color="primary"
              className="rounded-pill plan-button"
              href={t('global:letsTalkUrl')}
            >
              {t('plans:translationContactUsButton')}
            </Button>
          </div>
          <hr className="my-3 mt--3" />
          <h4> {t('plans:enterprisePlanData.featuresHeading')}</h4>
          <List type="unstyled">
            {planData.features.map((feature, index) => (
              <li key={index} className="mt-2 d-flex align-items-start">
                <div className="icon-container">
                  {feature.available ? (
                    <i className="fas fa-times"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </div>
                <span className="text-container">{feature.text}</span>
              </li>
            ))}
          </List>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EnterprisePlan;
