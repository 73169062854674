export function isValidUrl(url) {
  const pattern = /\/view\/\d+\/[a-f0-9]+(\?step=.+\.png)?$/;
  return pattern.test(url);
}
export function isValidNumber(stepNumber) {
  return !isNaN(parseFloat(stepNumber)) && isFinite(stepNumber);
}

export function replaceViewWithEmbed(url) {
  if (!url) {
    return;
  }
  if (url.includes('/view/')) {
    return url.replace('/view/', '/embed/');
  }
  return url;
}

export function fetchIdFromUr(url) {
  const pathSegments = url.split('/');
  const viewIndex = pathSegments.indexOf('view');
  const id = pathSegments[viewIndex + 1];
  return id;
}
