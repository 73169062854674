import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { miscReducer } from './reducers/misc.reducer';
import { organizationReducer } from './reducers/organization.reducer';
import { organizationMembership } from './reducers/organizationMembership'; // defaults to localStorage for web
import { userReducer } from './reducers/users.reducer';
import { flowsReducer } from './reducers/flows.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  organization: organizationReducer,
  organizationMembership: organizationMembership,
  flows: flowsReducer,
  misc: miscReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
