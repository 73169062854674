import {
  CLEAR_ACTIVE_INDEXES,
  SET_ACTIVE_INDEX,
  SET_JUST_LOGGED_IN,
} from '../actions/misc.actions';

export const miscReducer = (
  state = { justLoggedIn: false, activeIndexes: {} },
  action
) => {
  switch (action.type) {
    case SET_JUST_LOGGED_IN: {
      const justLoggedIn = action.justLoggedIn;
      return { ...state, justLoggedIn: justLoggedIn };
    }
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndexes: {
          ...state.activeIndexes,
          [action.setActiveIndex.key]: action.setActiveIndex.activeIndex,
        },
      };

    case CLEAR_ACTIVE_INDEXES:
      return {
        ...state,
        activeIndexes: {},
      };

    default:
      return state;
  }
};
