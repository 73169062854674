import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

import MemberLoader from './contentLoaders/MemberLoader';
import Header from './Header';
import InviteMemberModal from './InviteMemberModal';
import NotificationType from '../constants/notificationType';
import ORGANIZATION_MEMBERSHIP_ROLE from '../constants/organizationMembershipRole';
import { memberService } from '../services/member.service';
import { openNotification } from '../utlis/notification';

const ManageMembers = () => {
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const { t, ready } = useTranslation(['global', 'members', 'notifications']);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const limit = 8;

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        let response = await memberService.getAllOrganizationMembers(
          organization.id,
          currentPage,
          limit,
          searchText
        );

        //remove the current user from the list
        // response = response.filter((item) => item.user.id !== user.id);

        setMembers(response.members);
        setTotalPages(Math.ceil(response.total / response.limit));
        setLoading(false);
      } catch (error) {
        openNotification(error.error, NotificationType.ERROR);
        setLoading(false);
        setMembers([]);
      }
    };
    fetchMembers();
  }, [currentPage, searchText]);

  if (!ready) return null;

  const changeRole = async (e, id, role) => {
    e.preventDefault();

    try {
      await memberService.updateMembership(
        organization.id,
        id,
        undefined,
        role
      );

      const newMembers = members.map((obj) => {
        if (obj.id === id) {
          const object = obj;
          object.role = role;
          return object;
        } else {
          return obj;
        }
      });

      setMembers(newMembers);

      openNotification(
        t('notifications:memberUpdatedSuccess'),
        NotificationType.SUCCESS
      );
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };
  const changeStatus = async (e, id, updatedStatus) => {
    e.preventDefault();

    try {
      await memberService.updateMembership(organization.id, id, updatedStatus);

      const newMembers = members.map((obj) => {
        if (obj.id === id) {
          const object = obj;
          object.status = updatedStatus;
          return object;
        } else {
          return obj;
        }
      });

      setMembers(newMembers);

      openNotification(
        t('notifications:memberUpdatedSuccess'),
        NotificationType.SUCCESS
      );
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };
  const deleteMembership = async (e, id) => {
    e.preventDefault();

    try {
      await memberService.deleteMembership(organization.id, id);
      const membersList = members.filter((member) => member.id !== id);
      setMembers(membersList);

      openNotification(
        t('notifications:memberDeleteSuccess'),
        NotificationType.SUCCESS
      );
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  const pushNewMember = (newMember) => {
    members.push(newMember);
    toggle();
  };
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <Header activeLocation={'manageMembers'} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col className={'col-12'}>
            <Card>
              <CardHeader className="d-flex justify-content-between h3 bg-white border-0">
                <div>{t('members:members')}</div>
                <InputGroup
                  className="input-group-alternative"
                  style={{ maxWidth: '30%' }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('global:search')}
                    type="text"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  {searchText && (
                    <InputGroupAddon addonType="prepend">
                      <Button onClick={clearSearchText}>
                        <i className="fas fa-times" />
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                <Button
                  color="primary"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  className="float-left"
                  onClick={toggle}
                >
                  {t('members:inviteMember')}
                </Button>
              </CardHeader>
              <CardBody style={{ marginTop: '-2rem' }}>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col-5">{t('global:name')}</th>
                      <th scope="col-3">{t('global:email')}</th>
                      <th scope="col-1">{t('global:status')}</th>
                      <th scope="col-1">{t('global:role')}</th>
                      <th scope="col-1" />
                    </tr>
                  </thead>
                  {loading ? (
                    <MemberLoader />
                  ) : (
                    members.length > 0 &&
                    members.map((member, index) => (
                      <tbody key={index}>
                        <tr key={member.id}>
                          <td key={index}>
                            <Media className="align-items-center">
                              <div
                                className="avatar rounded-circle mr-3"
                                style={{ backgroundColor: '#33DAE2' }}
                              >
                                {`${member.user.firstName.charAt(
                                  0
                                )} ${member.user.lastName.charAt(0)}`}
                              </div>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {`${member.user.firstName} ${member.user.lastName}`}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>{member.user.email}</td>
                          <td>
                            {member.status === 'inactive' && (
                              <Badge color="danger" pill>
                                {t('global:inactive')}
                              </Badge>
                            )}
                            {member.status === 'active' && (
                              <Badge color="success" pill>
                                {t('global:active')}
                              </Badge>
                            )}
                            {member.status === 'invited' && (
                              <Badge className="custom-warning" pill>
                                {t('global:invited')}
                              </Badge>
                            )}
                            {member.status === '' && ''}
                          </td>
                          <td style={{ width: '11%' }}>
                            <InputGroup className="input-group-alternative">
                              <Input
                                type="select"
                                onChange={async (e) => {
                                  await changeRole(
                                    e,
                                    member.id,
                                    e.target.value
                                  );
                                }}
                                style={{ minWidth: '100px' }}
                                defaultValue={member.roleName}
                                disabled={member.id === user.id}
                                required
                              >
                                <option
                                  value={ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR}
                                >
                                  {ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR.charAt(
                                    0
                                  ).toUpperCase() +
                                    ORGANIZATION_MEMBERSHIP_ROLE.AUTHOR.slice(
                                      1
                                    )}
                                </option>
                                <option
                                  value={ORGANIZATION_MEMBERSHIP_ROLE.ADMIN}
                                >
                                  {ORGANIZATION_MEMBERSHIP_ROLE.ADMIN.charAt(
                                    0
                                  ).toUpperCase() +
                                    ORGANIZATION_MEMBERSHIP_ROLE.ADMIN.slice(1)}
                                </option>
                                <option
                                  value={ORGANIZATION_MEMBERSHIP_ROLE.CHATTER}
                                >
                                  {ORGANIZATION_MEMBERSHIP_ROLE.CHATTER.charAt(
                                    0
                                  ).toUpperCase() +
                                    ORGANIZATION_MEMBERSHIP_ROLE.CHATTER.slice(
                                      1
                                    )}
                                </option>
                              </Input>
                            </InputGroup>
                          </td>
                          {member.id !== user.id && (
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  role="button"
                                  color={''}
                                >
                                  <i
                                    style={{
                                      color: '#00284c',
                                      fontSize: '1.3rem',
                                    }}
                                    className="fas fa-ellipsis-v"
                                  />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={(e) =>
                                      deleteMembership(e, member.id)
                                    }
                                  >
                                    {t('global:delete')}
                                  </DropdownItem>
                                  {member.status === 'active' && (
                                    <DropdownItem
                                      onClick={(e) =>
                                        changeStatus(e, member.id, 'inactive')
                                      }
                                    >
                                      {t('global:deActivate')}
                                    </DropdownItem>
                                  )}
                                  {member.status === 'inactive' && (
                                    <DropdownItem
                                      onClick={(e) =>
                                        changeStatus(e, member.id, 'active')
                                      }
                                    >
                                      {t('global:active')}
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    ))
                  )}
                </Table>
              </CardBody>
              <CardFooter>
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={currentPage === 1 ? 'disabled' : ''}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePrevPage();
                        }}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages)].map((e, i) => (
                      <PaginationItem
                        className={currentPage === i + 1 ? 'active' : ''}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentPage(i + 1);
                          }}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      className={currentPage === totalPages ? 'disabled' : ''}
                    >
                      <PaginationLink
                        onClick={(e) => {
                          e.preventDefault();
                          handleNextPage();
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal toggle={toggle} isOpen={isModalOpen} size={'md'} centered>
          <ModalHeader className="card-header" toggle={toggle}>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {t('members:inviteTeamMember')}
            </span>
          </ModalHeader>

          <InviteMemberModal pushNewMember={pushNewMember} />
        </Modal>
      </Container>
    </>
  );
};
export default ManageMembers;
