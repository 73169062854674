import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

const AuthNavbar = () => {
  const { t, ready } = useTranslation('auth');
  if (!ready) return null;
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container className="px-4">
          <div className="nav-link-icon nav-link">
            <NavbarBrand href="https://getflowshare.com/">
              <img
                alt="..."
                src={require('../assets/img/brand/flowShare-logo-white.png')}
              />
            </NavbarBrand>
          </div>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/app">
                    <img
                      alt="..."
                      src={require('../assets/img/brand/flowShare-logo.png')}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  as="a"
                  href="https://support.getflowshare.com/"
                >
                  <i className="ni ni-support-16" />
                  <span className="nav-link-inner--text">
                    {t('auth:authNavbarGetSupportItem')}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AuthNavbar;
