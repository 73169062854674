const AssistAnswerLoader = () => {
  return (
    <lord-icon
      src="https://cdn.lordicon.com/oenaqifs.json"
      trigger="loop"
      delay="1000"
      colors="primary:#ffffff,secondary:#2db577"
      style={{ width: '100px', height: '100px', marginTop: '-31px' }}
    />
  );
};

export default AssistAnswerLoader;
