import { useTranslation } from 'react-i18next';
import { Col, Card, Row, CardBody } from 'reactstrap';

const PostSignupMessage = () => {
  const { t, ready } = useTranslation(['auth']);
  if (!ready) return null;
  return (
    <Col lg="7" md="9">
      <Card className="bg-secondary shadow border-0 text-center">
        <CardBody>
          <h2> {t('auth:postSignupMessageHeader')} </h2>
          <Row className="justify-content-center mb-2">
            <span style={{ fontSize: '1rem' }}>
              {t('auth:postSignupMessageText')}
            </span>
          </Row>
          <Row className="justify-content-center">
            <span>{t('auth:postSignupMessageFooter')}</span>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PostSignupMessage;
