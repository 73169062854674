import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';

import { handleError } from '../utlis/errorHandler';
import { getAuthHeader } from '../utlis/getAuthHeader';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];

export const licenseService = {
  getLicenses,
  deActivate,
};

function deActivate(id, activateId, versionId) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    activateId: activateId,
    versionId: versionId,
  };

  return axios
    .put(`${BACKEND_URL}/api/license-key/${id}`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function getLicenses() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(`${BACKEND_URL}/api/license-key`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
