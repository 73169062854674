import { SET_ORGANIZATION_MEMBERSHIP } from '../actions/organizationMembership.actions';

export const organizationMembership = (state = [], action) => {
  switch (action.type) {
    case SET_ORGANIZATION_MEMBERSHIP:
      return action.organizationMembership;
    default:
      return state;
  }
};
