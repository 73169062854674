import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { userService } from 'services/user.service';

import PasswordRequirements from './PasswordRequirements';
import NotificationType from '../constants/notificationType';
import { PASSWORD_CHANGE_REGEX } from '../constants/passwordChangeRegex';
import Routes from '../routes/routes';
import { openNotification } from '../utlis/notification';
import AuthNavbar from './AuthNavbar';

const PasswordReset = () => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);

  const params = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      email !== '' &&
      password !== '' &&
      new RegExp(`${PASSWORD_CHANGE_REGEX}`).test(password) &&
      confirmPassword !== '' &&
      password === confirmPassword
    ) {
      setReadyToSend(false);
    } else {
      setReadyToSend(true);
    }
  }, [email, password, confirmPassword]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await userService.resetPassword({
        newPassword: password,
        token: params.token,
        email: email,
      });
      openNotification(
        t('notifications:passwordChangeSuccess'),
        NotificationType.SUCCESS
      );

      navigate(Routes.auth.login);
    } catch (e) {
      setLoading(false);
      setReadyToSend(false);
      document.getElementById('resetForm').reset();
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  return (
    <>
      <AuthNavbar />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader>
            <h4>{t('auth:passwordResetHeading')}</h4>
          </CardHeader>
          <CardBody>
            <Form id={'resetForm'} onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('global:email')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('global:email')}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('auth:passwordResetNewPasswordLabel')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('auth:passwordResetNewPasswordPlaceHolder')}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    defaultValue={password}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <label className="form-control-label">
                  {t('auth:passwordResetConfirmNewPasswordLabel')}
                </label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t(
                      'auth:passwordResetConfirmNewPasswordPlaceHolder'
                    )}
                    type="password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    defaultValue={confirmPassword}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <label className="form-control-label">
                  {t('auth:passwordResetPasswordRequirements')}
                </label>
                <PasswordRequirements />
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={readyToSend}
                  className="my-4"
                  color="primary"
                  type="submit"
                >
                  {t('global:reset')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link style={{ color: 'white' }} to="/auth/login/">
              <small>{t('global:signIn')}</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <a
              style={{ color: 'white' }}
              href="https://getflowshare.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <small> {t('auth:passwordResetGetFlowShareText')} </small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PasswordReset;
