import Index from 'components/Index';
import AppLayout from 'layouts/App';
import AuthLayOut from 'layouts/Auth';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import './customCss/custom.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import Authorization from './components/Authorization';
import Billing from './components/Billing';
import ChatBot from './components/chatBot/ChatBot';
import FlowEmbedViewer from './components/FlowEmbedViewer';
import FlowViewer from './components/FlowViewer';
import LoginWrapper from './components/LoginWrapper';
import ManageFlows from './components/ManageFlows';
import ManageLicenses from './components/ManageLicenses';
import ManageMembers from './components/ManageMembers';
import PasswordForgot from './components/PasswordForgot';
import PasswordReset from './components/PasswordReset';
import Plans from './components/Plans';
import UserProfile from './components/UserProfile';
import i18n from './i18n';
import store from './redux';
import ProtectedRoutes from './routes/protectedRoutes';
import PublicRoutes from './routes/publicRoutes';
import LoginFlowShare from 'components/flowshareLogin/LogInFlowShare';
import FlowShareLayOut from 'layouts/FlowShare';
import SignUpWrapper from 'components/signUp/SignUpWrapper';
import EmailVerify from 'components/signUp/EmailVerify';
import RegistrationSuccessfull from 'components/signUp/RegistrationSuccessfull';

// import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} />
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes>
          <Route path="/app" element={<AppLayout />}>
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="" element={<ProtectedRoutes Component={Index} />} />
            <Route
              path="billing"
              element={
                <ProtectedRoutes Component={Billing} requiredRole={['admin']} />
              }
            />
            <Route
              path="user-profile"
              element={<ProtectedRoutes Component={UserProfile} />}
            />
            <Route
              path="manage-licenses"
              element={
                <ProtectedRoutes
                  Component={ManageLicenses}
                  requiredRole={['admin', 'author']}
                />
              }
            />
            <Route
              path="manage-members"
              element={
                <ProtectedRoutes
                  Component={ManageMembers}
                  requiredRole={['admin']}
                />
              }
            />
            <Route
              path="manage-flows"
              element={<ProtectedRoutes Component={ManageFlows} />}
            />
            <Route
              path="manage-plans"
              element={
                <ProtectedRoutes Component={Plans} requiredRole={['admin']} />
              }
            />
          </Route>
          <Route path="/" element={<Navigate to="/app/" replace />} />
          <Route path="/auth" element={<AuthLayOut />}>
            <Route path="" element={<Navigate to="/auth/login" replace />} />
            <Route
              path="verify-email/:token"
              element={<PublicRoutes Component={EmailVerify} />}
            />
            <Route
              path="registration-sent"
              element={<PublicRoutes Component={RegistrationSuccessfull} />}
            />
            <Route
              path="sign-up"
              element={<PublicRoutes Component={SignUpWrapper} />}
            />
            <Route
              path="login"
              element={<PublicRoutes Component={LoginWrapper} />}
            />

            <Route
              path="password-forgot"
              element={<PublicRoutes Component={PasswordForgot} />}
            />
            <Route
              path="password-reset/:token"
              element={<PublicRoutes Component={PasswordReset} />}
            />
          </Route>
          <Route path="/flowshare" element={<FlowShareLayOut />}>
            <Route
              path="login"
              element={<PublicRoutes Component={LoginFlowShare} />}
            />
          </Route>
          <Route
            path="flowshare-assist"
            element={<ProtectedRoutes Component={ChatBot} />}
          />
          <Route path="authorize" element={<Authorization />} />
          <Route path="view/:id/:identifier" element={<FlowViewer />} />
          <Route path="embed/:id/:identifier" element={<FlowEmbedViewer />} />
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);
