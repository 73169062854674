import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import PasswordRequirements from './PasswordRequirements';
import NotificationType from '../constants/notificationType';
import { PASSWORD_CHANGE_REGEX } from '../constants/passwordChangeRegex';
import { userService } from '../services/user.service';
import { openNotification } from '../utlis/notification';

const PasswordUpdate = () => {
  const { t, ready } = useTranslation(['profile', 'notifications']);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      currentPassword !== '' &&
      newPassword !== '' &&
      new RegExp(`${PASSWORD_CHANGE_REGEX}`).test(newPassword) &&
      confirmNewPassword !== '' &&
      newPassword === confirmNewPassword
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [currentPassword, newPassword, confirmNewPassword]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await userService.changePassword(currentPassword, newPassword);

      openNotification(
        t('notifications:passwordChangeSuccess'),
        NotificationType.SUCCESS
      );
    } catch (error) {
      openNotification(error.error, NotificationType.ERROR);
    }

    setReadyToSend(false);
    setLoading(false);
    document.getElementById('passwordChangeFrom').reset();
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <>
      <Row className={'mt-4 mb-3'}>
        <Col className="order-xl-1" xl="12">
          <Card>
            <CardHeader>
              <h3>{t('profile:changePasswordHeading')}</h3>
            </CardHeader>

            <CardBody className="bg-secondary shadow">
              <Row>
                <Col className="order-xl-1" xl="6">
                  <Form id={'passwordChangeFrom'} onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <label className="form-control-label">
                        {t('profile:currentPasswordLabel')}
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder={t(
                            'profile:currentPasswordPlaceHolderText'
                          )}
                          type="password"
                          onChange={(e) => {
                            setCurrentPassword(e.target.value);
                          }}
                          defaultValue={currentPassword}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <label className="form-control-label">
                        {t('profile:newPasswordLabel')}
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t('profile:newPasswordPlaceHolderText')}
                          type="password"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          defaultValue={newPassword}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <label className="form-control-label">
                        {t('profile:confirmNewPasswordLabel')}
                      </label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={t(
                            'profile:confirmNewPasswordPlaceHolderText'
                          )}
                          type="password"
                          onChange={(e) => {
                            setConfirmNewPassword(e.target.value);
                          }}
                          defaultValue={confirmNewPassword}
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Button
                        disabled={!readyToSend}
                        className="my-4 float-left"
                        color="primary"
                        type="submit"
                      >
                        {t('profile:changePasswordButtonText')}
                        {loading && (
                          <span className="spinner-border spinner-border-sm" />
                        )}
                      </Button>
                    </FormGroup>
                  </Form>
                </Col>
                <Col className="order-xl-1" xl="6">
                  <label className="form-control-label">
                    {t('profile:passwordRequirementsHeading')}
                  </label>
                  <p>{t('profile:passwordRequirementsText')}</p>
                  <PasswordRequirements
                    liClass={'mb-2'}
                    ulClass={'small text-muted'}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PasswordUpdate;
