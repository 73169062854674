import ContentLoader from 'react-content-loader';

const TableCellSkeleton = ({ width }) => {
  const fixedWidth = width * 2.5;

  return (
    <td style={{ width: `${width}%` }}>
      <ContentLoader
        width={fixedWidth}
        height="20"
        viewBox={`0 0 ${fixedWidth} 20`}
        speed={2}
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
      </ContentLoader>
    </td>
  );
};

export default TableCellSkeleton;
