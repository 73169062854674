import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import questionIcon from '../../assets/img/icons/question.svg';
import { flowAssistService } from '../../services/flowShareAssist.service';

const FeedbackModal = ({ triggerOpen, onClose, chatHistory, feedbackType }) => {
  const { t, ready } = useTranslation('chatbot');
  const user = useSelector((state) => state.user);
  const [openFeedbackPopUp, setOpenFeedbackPopUp] = useState(false);
  const [anonymousCheck, setAnonymousCheck] = useState(true);
  const [feedbackText, setFeedbackText] = useState('');

  useEffect(() => {
    if (triggerOpen) {
      setOpenFeedbackPopUp(true);
    }
  }, [triggerOpen]);

  if (!ready) return null;

  const sendFeedback = () => {
    let modifiedChatHistory = chatHistory.map((chat) => {
      let modifiedChat = { ...chat };

      delete modifiedChat.imagesURLs;

      return modifiedChat;
    });

    flowAssistService.feedback({
      anonymous: anonymousCheck,
      feedbackText: feedbackText,
      feedbackType: feedbackType,
      chatHistory: modifiedChatHistory,
      userId: user.id,
    });

    handleClose();
  };

  const handleClose = () => {
    setOpenFeedbackPopUp(false);
    clearFeedback();
    onClose();
  };

  const clearFeedback = () => {
    setAnonymousCheck(false);
    setFeedbackText(null);
  };
  return (
    <Modal isOpen={openFeedbackPopUp} toggle={handleClose} centered>
      <ModalHeader
        toggle={handleClose}
        style={{ paddingRight: '10px', paddingTop: '10px' }}
      ></ModalHeader>
      <ModalBody
        className={
          'd-flex flex-column align-items-center justify-content-center '
        }
      >
        <div>
          <img
            src={questionIcon}
            className={'mt--5'}
            style={{ height: '80px', width: '80px' }}
            alt={'question-emoji'}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>{t('feedbackModalMessage')}</div>
        <Input
          type="textarea"
          onChange={(e) => setFeedbackText(e.target.value)}
        />
        <Label check style={{ marginTop: '5px' }}>
          <Input
            type="checkbox"
            onChange={(e) => setAnonymousCheck(e.target.checked)}
          />{' '}
          {t('feedbackModalWarning')}
        </Label>
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'center', paddingTop: '0px' }}>
        <Button color="primary" onClick={sendFeedback}>
          {t('feedbackModalSendButtonText')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
