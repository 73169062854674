import { SET_ORGANIZATION } from 'redux/actions/organization.actions';

export const organizationReducer = (state = [], action) => {
  switch (action.type) {
    case SET_ORGANIZATION:
      return action.organization;
    default:
      return state;
  }
};
