import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Modal, Table } from 'reactstrap';

const FlowVersionsModal = ({ flowsMetaData, isOpen, toggleModal }) => {
  const { t, ready } = useTranslation(['global', 'flows', 'notifications']);
  if (!ready) return null;

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className={'share-flow-modal'}
        size={'lg'}
        centered
      >
        <Card>
          <CardHeader className={'d-flex justify-content-between'}>
            <div>{t('flows:versionHistory')}</div>
            <div>
              <Button close onClick={toggleModal} />
            </div>
          </CardHeader>
          <CardBody style={{ backgroundColor: 'rgb(249 248 248)' }}>
            <Table responsive>
              <thead className={'thead-light'}>
                <tr>
                  <th className={'col-7'}>{t('global:title')}</th>
                  <th className={'col-2'}> {t('global:versionNumber')}</th>
                  <th className={'col-3'}> {t('global:date')}</th>
                </tr>
              </thead>
              <tbody>
                {flowsMetaData.map((flowMetaData, index) => (
                  <tr key={index}>
                    <td>
                      <a
                        href={flowMetaData.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: '#005883',
                          display: 'inline-block',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        title={flowMetaData.flowMetaData.title}
                      >
                        {flowMetaData.flowMetaData.title}
                      </a>
                    </td>
                    <td>{flowMetaData.flowMetaData.version}</td>
                    <td>
                      {new Date(flowMetaData.flowMetaData.updatedAt)
                        .toLocaleDateString('en-us', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                        })
                        .replace(/\//g, '.')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default FlowVersionsModal;
