import { useTranslation } from 'react-i18next';
import { Button, Container } from 'reactstrap';

const FlowNotFound = () => {
  const { t, ready } = useTranslation(['flows', 'global']);
  if (!ready) return null;
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center ">
      <h1 style={{ fontSize: '10rem', color: '#005883', fontWeight: '200' }}>
        404
      </h1>
      <h2 style={{ color: '#0f3a5d' }}>{t('flows:flowNotFoundHeading')}</h2>
      <p>{t('flows:flowNotFoundParagraphTop')}</p>
      <p>{t('flows:flowNotFoundParagraphBottom')}</p>
      <div className="d-flex justify-content-center">
        <Button
          onClick={() =>
            (window.location.href = `${t('global:flowShareWebsite')}`)
          }
          className="btn-errorpage"
        >
          {t('flows:flowNotFoundButton')}
        </Button>
      </div>
    </Container>
  );
};

export default FlowNotFound;
