import { SET_FLOWS_COUNT } from 'redux/actions/flows.actions';

const initialState = {
  totalFlows: 0,
};

export const flowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOWS_COUNT:
      return {
        ...state,
        flowsCount: action.flowsCount,
      };
    default:
      return state;
  }
};
