import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';

import { handleError } from '../utlis/errorHandler';
import { getAuthHeader } from '../utlis/getAuthHeader';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];

export const memberService = {
  inviteTeamMember,
  getAllOrganizationMembers,
  updateMembership,
  deleteMembership,
  getCurrentUserMembership,
};

function getCurrentUserMembership() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };
  return axios
    .get(
      `${BACKEND_URL}/api/organization-membership/current-user-membership`,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function deleteMembership(organizationId, membershipId) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .delete(
      `${BACKEND_URL}/api/organization-membership/${organizationId}/${membershipId}`,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
function updateMembership(
  organizationId,
  membershipId,
  updatedStatus = undefined,
  role = undefined
) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    status: updatedStatus,
    role: role,
  };

  return axios
    .put(
      `${BACKEND_URL}/api/organization-membership/${organizationId}/${membershipId}`,
      data,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function inviteTeamMember(organizationId, email, role, firstName, lastName) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    email: email,
    role: role,
    firstName: firstName,
    lastName: lastName,
  };

  return axios
    .post(
      `${BACKEND_URL}/api/organization-membership/${organizationId}`,
      data,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function getAllOrganizationMembers(id, page, limit, searchText) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  let url = `${BACKEND_URL}/api/organization-membership/${id}?`;

  if (page) url += `page=${page}&`;
  if (limit) url += `limit=${limit}&`;
  if (searchText) url += `search_text=${searchText}`;

  return axios
    .get(url, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
