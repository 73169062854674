import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

import { setActiveIndex } from '../../redux/actions/misc.actions';

const DropdownItemChatBot = (props) => {
  const { t, ready } = useTranslation('chatbot');
  const dispatch = useDispatch();
  const activeIndex = useSelector((state) =>
    state.misc.activeIndexes[props.itemKey]
      ? state.misc.activeIndexes[props.itemKey]
      : +props.item.step_number || 0
  );

  // Dispatch an action to initialize the active index when the component mounts
  useEffect(() => {
    dispatch(
      setActiveIndex(
        props.itemKey,
        +props.item.step_number > 0
          ? +props.item.step_number - 1
          : +props.item.step_number - 1 || 0
      )
    );
  }, [dispatch, props.itemKey, props.item.step_number]);

  if (!ready) return null;

  const updateIndex = (newIndex) => {
    dispatch(setActiveIndex(props.itemKey, newIndex));
    updateURL(newIndex);
  };

  const next = () => {
    const nextIndex =
      activeIndex === props.item.imagesURLs.length - 1 ? 0 : activeIndex + 1;
    updateIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? props.item.imagesURLs.length - 1 : activeIndex - 1;
    updateIndex(nextIndex);
  };

  const updateURL = (index) => {
    props.item.source = `${props.item.base_url}?step=${props.item.imagesURLs[index].name}`;
  };

  return (
    <div className={props.isOpen ? 'open' : ''}>
      <button className="questionBar" onClick={props.onToggle}>
        {props.item.user}
      </button>
      <div className="answerContent">
        <pre className={' pr-3 pre-wrap'}>{props.item.answer}</pre>

        {props.item.showImages && (
          <div className={'mt-3'}>
            <span>
              {t('assistSuggestionFirstHalf')}
              <a
                href={props.item.source}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`"${props.item.title}"`}
              </a>
              {t('assistSuggestionSecondHalf')} {props.item.author}.
            </span>
          </div>
        )}

        {props.item.showImages && (
          <Carousel
            keyboard={false}
            id={`dropdown-${props.item.step_number}`}
            className="mt-4 carousel-dropdown"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            ride="carousel"
            interval={false}
          >
            {props.item.imagesURLs.map((src, idx) => (
              <CarouselItem key={idx}>
                <a
                  href={props.item.source}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={src.src} alt={`image-${idx}`} />
                </a>
              </CarouselItem>
            ))}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default DropdownItemChatBot;
