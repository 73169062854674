import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';
import { Row } from 'reactstrap';

import EnterpriesPlan from './EnterpriesPlan';
import FreePlan from './FreePlan';
import ProfessionalPlan from './ProfessionalPlan';
import { organizationService } from '../../services/organization.service';
import { openNotification } from 'utlis/notification';
import NOTIFICATION_TYPES from 'constants/notificationType';

const PricingPage = () => {
  const { t, ready } = useTranslation(['plans']);
  const [showMonthlyPrices, setShowMonthlyPrices] = useState(true);
  const organization = useSelector((state) => state.organization);
  const [loading, setLoading] = useState(false);

  if (!ready) return null;
  const generateCustomerCheckoutSession = async (priceId) => {
    setLoading(true);

    try {
      const response =
        await organizationService.generateCustomerCheckoutSession(
          organization.id,
          priceId
        );
      window.location.replace(response.url);
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
    }
  };

  return (
    <>
      <Row className="justify-content-center  mt-2 ml-2 mr-2 gy-5 gy-lg-0 gx-xl-5">
        <label
          className="d-flex align-items-center "
          style={{ gap: '10px' }}
          htmlFor="material-switch"
        >
          <span style={{ fontSize: '23px' }}>
            {t('plans:plansToggleTextYearly')}
          </span>
          <Switch
            checked={showMonthlyPrices}
            onChange={() => {
              setShowMonthlyPrices(!showMonthlyPrices);
            }}
            className="react-switch"
            id="material-switch"
          />
          <span style={{ fontSize: '23px' }}>
            {t('plans:plansToggleTextMonthly')}
          </span>
        </label>
      </Row>
      <Row className="align-items-stretch mr-0 ml-0">
        <FreePlan />
        <ProfessionalPlan
          showMonthlyPrice={showMonthlyPrices}
          generateCustomerCheckoutSession={generateCustomerCheckoutSession}
          loading={loading}
        />
        <EnterpriesPlan />
      </Row>
    </>
  );
};

export default PricingPage;
