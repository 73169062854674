import useGoogleTagManager from 'components/hook/GoogleTagManager';
import PostSignupMessage from './PostSignupMessaage';
import SignUpNavbar from './SignUpNavbar';

const RegistrationSuccessfull = () => {
  useGoogleTagManager('GTM-WN6ZC4L');
  return (
    <>
      <SignUpNavbar />
      <PostSignupMessage />
    </>
  );
};

export default RegistrationSuccessfull;
